.md-icon {
  .material-icon();

  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: .3;
  }

  &:after {
    opacity: 1;
  }

  &.md-icon-small {
    font-size: @md-icon-size-small;
  }

  &.md-icon-relative-size {
    font-size: unit(@md-icon-size, em);

    &.md-icon-small {
      font-size: unit(@md-icon-size-small, em);
    }
  }

  &.md-icon-xsmall {
    font-size: @md-icon-size-xsmall;
  }

  // Transformed icons.
  &.flip-90 {
    transform: rotateZ(90deg);
  }

  &-flipped-wrapper {
    // Fix for Safari browser.
    perspective: 1px;
  }

  [dir=ltr] &,
  [dir=rtl] [dir=ltr] & {
    &-flipped-rtl {
      transform: rotateY(0deg);
    }

    &-flipped-ltr {
      transform: rotateY(-180deg);
    }
  }

  [dir=rtl] & {
    &-flipped-ltr {
      transform: rotateY(0deg);
    }

    &-flipped-rtl {
      transform: rotateY(-180deg);
    }
  }

  // Italic icons.
  &-italic {
    font-style: italic;
  }
}

// Custom icons.
.custom-icon {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: @md-icon-size;
  vertical-align: middle;
  color: inherit;

  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.md-icon-blend {
  position: relative;

  .md-icon + .md-icon {
    @size: calc(@md-icon-size + .5rem);
    @pos: calc((@md-icon-size - @size) / 2);

    .material-icon(@size);
    position: absolute;
    top: @pos;
    inset-inline-start: @pos;
  }
}
