.enrollment-requests-list {
  .question-answer-cell {
    min-width: 8em;
  }

  .date-cell {
    min-width: 12em;
  }

  thead {
    .question-answer-cell > div {
      .text-ellipsis-box(4);
    }
  }

  tbody {
    .question-answer-cell > div[data-show-more] {
      max-height: calc(@table-font-size * @table-line-height * 4);
    }
  }
}

.enrollment-requests-questionnaire-modal {
  .mtx-custom-scrollbar {
    max-height: 65vh;
  }

  .form-type-checkbox {
    display: flex;
    margin: 0 0 @form-item-margin-bottom;

    input[type="checkbox"] {
      min-width: @md-icon-size;
    }

    label {
      margin-top: -.25rem;
    }
  }
}
