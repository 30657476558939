.mtx-report-popular-trainings {
  .item {
    &-title a {
      color: inherit;
    }

    .mtx-progress {
      &-bar {
        height: @global-height-mini;
        background-color: transparent;
      }

      &-bar-inner {
        position: relative;
        border-radius: calc(@global-height-mini / @progress-border-radius-ratio);
      }

      &-value {
        font-weight: @global-font-weight-semibold;

        a {
          color: inherit;
        }
      }
    }

    @media all and (min-width: @breakpoint-medium) {
      .clearfix();

      & + .item {
        margin-top: @global-margin-large;
      }

      &-title {
        width: 35%;

        a {
          .text-ellipsis();
          padding-inline-end: @global-margin-large;
        }
      }

      &-progress {
        width: 65%;
      }

      .mtx-progress-value a {
        padding-inline-start: @global-margin;
      }

      [dir=ltr] & {
        & > * {
          float: left;
        }
      }

      [dir=rtl] & {
        & > * {
          float: right;
        }
      }
    }

    @media all and (max-width: @breakpoint-small-max) {
      & + .item {
        margin-top: @global-margin-medium;
      }

      &-title {
        margin-bottom: @global-margin-small / 2;

        a {
          .text-ellipsis-box(2);
        }
      }

      .mtx-progress-value a {
        padding-inline-start: @global-margin-small;
      }
    }
  }
}
