// Tokens wrapper.
.token-click-insert {
  position: absolute;
  top: calc(@global-line-height * @global-font-size);
  inset-inline-end: 0;
  width: @form-height;
  height: @form-height;
  border-radius: @inputs-border-radius;
  padding: 0 @global-margin;
  transition: all .15s ease-in-out;

  &-wrapper {
    position: relative;

    .token-insert {
      padding-inline-end: calc(@global-margin * 2 + @md-icon-size) !important;
    }
  }

  &-wrapper-no-label & {
    top: 0;
  }

  &-wrapper-states {
    [style*="display: none"] ~ * {
      display: none;
    }
  }

  .md-icon {
    line-height: @form-height;
  }
}

// Tokens.
.mtx-editor-token-key {
  display: inline-block;
  border-radius: @global-border-radius;
  padding: @global-margin-mini @global-margin;
  line-height: 1.2;
  transition: background-color .15s linear;
  cursor: default;
  margin: .125rem 0.0625rem;
}
