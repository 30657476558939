// Profile info section.
.user-profile {
  &-avatar {
    .circle(8rem);
  }

  &-info {
    .name-wrapper {
      .md-verified_user {
        color: @secondary_color;
      }
    }

    .name {
      .break-word();
      margin: 0;
    }

    .roles {
      margin: @global-margin-small 0 0;
    }

    .alias:before {
      content: " \2219 ";
      font-weight: 900;
      padding: 0 0.5rem;
    }

    .details {
      margin-top: @global-margin-large;

      & > li {
        position: relative;

        & + li {
          margin-top: @global-margin;
        }

        & > .md-icon {
          position: absolute;
        }

        [dir=ltr] & {
          padding-left: @md-icon-size + @global-margin;

          & > .md-icon {
            left: 0;
          }
        }

        [dir=rtl] & {
          padding-right: @md-icon-size + @global-margin;

          & > .md-icon {
            right: 0;
          }
        }
      }
    }
  }

  &-socials {
    margin-top: @global-margin-medium;
  }

  @media all and (min-width: @breakpoint-medium) {
    &-avatar {
      margin: 0 @global-margin-medium * 4 0 0;
    }

    &-info {
      max-width: 60%;
    }

    &-avatar,
    &-info {
      float: left;
    }

    [dir=rtl] & {
      &-avatar {
        margin: 0 0 0 @global-margin-medium * 4;
      }

      &-avatar,
      &-info {
        float: right;
      }
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    &-avatar {
      margin: 0 auto;
    }

    &-info {
      .name {
        margin-top: @global-margin;
      }

      .name, .roles {
        text-align: center;
      }
    }
  }
}

// Trainings section.
.mtx-user-trainings-section {
  .form-item-search {
    width: @exposed-input-width;
    .input-search-icon;

    // @Todo: Align .ctools-auto-submit-full-form {} styles.
    // See _drupal/views.less
    @media all and (max-width: @breakpoint-small-max) {
      width: 100%;
      margin-bottom: @global-margin-large;
    }
  }

  .item-list .pager.mtx-api-pager-load-more {
    margin-bottom: 0;
  }
}

// @Todo: Check this
#hu-social-activity-save-user-connect-form {
  form {
    padding: 10px;
  }

  fieldset {
    background: transparent;
    border: 0 none;
    padding: 0;
    margin-top: 0;

    &.collapsed {
      .fieldset-wrapper {
        visibility: visible !important;
        display: block !important;
      }
    }

    .fieldset-wrapper {
      margin-top: 0;
    }
  }

  legend {
    display: none;
  }

  .form-item {
    margin: 0;
  }
}

// Certificates section.
.mtx-user-certificates-section {
  .mtx-list-item {
    &.last-visible ~ .mtx-list-item {
      display: none;
    }

    .icon .md-icon {
      height: @list-icon-size;
      color: @secondary_color;

      &.is-expired {
        color: @_grey_color;
      }

      &:after {
        font-size: 2rem;
      }
    }

    .meta {
      margin-top: @global-margin-mini;
    }
  }
}
