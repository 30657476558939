// Expandable search
.mtx-expandable-search-form {
  .form-item-search {
    position: relative;
    min-width: @form-height;
    min-height: @form-height;

    .search-input {
      border-radius: @global-border-radius-circle;
      width: @form-height;
      min-width: @form-height;
      transition: all .2s ease-out;

      &::placeholder {
        max-height: 100%;
        white-space: pre-line;
        word-break: keep-all;
      }

      &,
      &:focus {
        border-color: transparent;
      }
    }

    &.search-active {
      .search-input {
        width: @mtx-search-field-expanded-width;
        min-width: @mtx-search-field-expanded-width;
      }

      .search-input-submit.uk-button {
        border-radius: @global-border-radius-circle;
      }
    }

    .search-input-submit {
      position: absolute;
      top: 0;

      [dir=ltr] & {
        right: 0;
      }

      [dir=rtl] & {
        left: 0;
      }
    }

    @media all and (max-width: @breakpoint-medium-max) {
      .search-input {
        position: absolute;
        top: 0;
        width: 100%;

        [dir=ltr] & {
          right: 0;
        }

        [dir=rtl] & {
          left: 0;
        }
      }
    }
  }
}
