// Common.
.mtx-translations-page-wrapper {
  // Operational buttons, filter.
  .filter-badges {
    margin-bottom: 0;
  }

  .filter-form {
    width: @exposed-input-width;

    .form-item {
      margin: 0;

      &.form-item-string {
        .input-search-icon();
      }
    }
  }

  // Form elements.
  input[type=text],
  .form-textarea-wrapper {
    & + .description {
      margin-top: @global-margin-small;
    }
  }

  .invariability-warning {
    color: @text-danger-color;
  }

  @media screen and (max-width: @breakpoint-mini-max) {
    #mtx-api-filter {
      float: none;
      margin: 0 0 @global-margin;

      [dir=ltr] & {
        margin-left: @global-margin;
      }
    }
  }
}

// Interface.
#mtx-translations-interface {
  padding: @global-margin 0 0;
  margin: 0;

  .headers {
    font-weight: @global-font-weight-semibold;
  }

  .row {
    display: table;
    width: 100%;

    // Actions
    & > * {
      display: table-cell;
      padding: @global-margin;

      &:first-child {
        padding-inline-start: 0;
      }

      &:last-child {
        padding-inline-end: 0;
      }
    }
  }

  .row + .row,
  .pager {
    border-top: 1px solid @global-border-color;
  }

  .context {
    word-break: break-all;
  }

  .headers,
  .string {
    font-size: @global-font-size-small;
  }

  .context,
  .context + .show-more-link,
  .codes {
    font-size: @global-font-size-xsmall;
  }

  .language-col {
    vertical-align: middle;
    position: relative;

    .mtx-actions {
      position: absolute;
      top: 50%;
      inset-inline-end: 0;
      margin-top: -(@md-icon-size / 2);
    }
  }

  .codes {
    padding-inline-end: @md-icon-size + @global-margin;
  }

  .pager {
    padding: @global-margin @global-margin 0;
  }

  @media all and (min-width: @breakpoint-medium) {
    .string-col {
      width: 80%;
    }

    .language-col {
      min-width: 20rem;
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .headers .language-col,
    .codes {
      display: none;
    }

    .language-col {
      min-width: 2rem;
    }
  }
}
