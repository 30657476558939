@mtx-progress-bar-height: .5rem; // 8px
@mtx-progress-bar-bg-color: @_grey_lighten_color;
@mtx-progress-bar-transition-duration: .5s;
@mtx-progress-value-transition-duration: .25s;
@mtx-circular-page-progress-size: 1rem;

.mtx-progress {
  position: relative;

  &-bar {
    height: @mtx-progress-bar-height;
    border-radius: calc(@mtx-progress-bar-height / @progress-border-radius-ratio);
    overflow: hidden;
    background-color: @mtx-progress-bar-bg-color;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }

    .mtx-learning-path-item .course-progress &,
    .mtx-course-el .course-progress & {
      border-radius: 0;
    }

    .mtx-learning-path-item .course-progress &,
    .mtx-course-el .course-progress & {
      &-inner {
        @border-radius: calc(@mtx-progress-bar-height / @progress-border-radius-ratio);

        [dir=ltr] & {
          border-radius: 0 @border-radius @border-radius 0;
        }

        [dir=rtl] & {
          border-radius: @border-radius 0 0 @border-radius;
        }

        &[aria-valuenow='100'] {
          border-radius: 0 !important;
        }
      }
    }

    .mtx-statistics-learner-progress & {
      @height: @global-height / 3; // 1rem

      height: @height;

      &,
      &-inner {
        border-radius: calc(@height / var(--progress-border-radius-ratio));
      }
    }
  }

  &-bar-inner {
    height: 100%;
    width: 0;
    transition: width @mtx-progress-bar-transition-duration linear;
  }

  &-delayed {
    opacity: 0;
    visibility: hidden;
  }

  &.value-inline,
  &.value-inline-inner {
    .mtx-progress-value {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
      opacity: 0;
      transition: opacity @mtx-progress-value-transition-duration linear @mtx-progress-bar-transition-duration;

      &.visible {
        opacity: 1;
      }
    }
  }

  &.value-inline {
    .mtx-progress-value {
      inset-inline-start: 100%;
    }
  }

  &.mtx-progress-still {
    .mtx-progress-bar-inner {
      transition: none;
    }

    &.value-inline,
    &.value-inline-inner {
      .mtx-progress-value {
        transition: none;
      }
    }
  }
}

.mtx-circular-progress {
  border-radius: 50%;

  &-bar {
    stroke: @_grey_light_color;
  }

  svg {
    transform: rotate(-90deg);
  }

  circle {
    transition: all .15s ease-in-out;
  }

  .md-icon {
    display: none;
    margin: 0 auto;
    text-align: center;
    font-weight: @global-font-weight-semibold;
    color: inherit;
  }

  &.progress-completed {
    svg {
      display: none;
    }

    .md-icon {
      display: block;
    }
  }

  .page-progress-wrapper & {
   .circle(@mtx-circular-page-progress-size);
    position: absolute;
    line-height: @mtx-circular-page-progress-size;

    &.progress-completed {
      .md-icon {
        line-height: @mtx-circular-page-progress-size;
        font-size: @mtx-circular-page-progress-size * .75;
      }
    }
  }

  .page-progress-hidden-wrapper & {
    display: none;
  }
}
