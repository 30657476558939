.view-organization-users {
  .views-exposed-form .form-item.form-type-select {
    display: none;
  }

  .views-field {
    &-name {
      min-width: 15rem;
      max-width: 18rem;
      word-break: break-word;

      .avatar {
        min-width: @mtx-users-avatar-size;

        &,
        img {
          .circle(@mtx-users-avatar-size);
        }
      }

      .text > div + div {
        margin-top: @global-margin-mini;
      }
    }

    &-created,
    &-field-user-organization {
      min-width: 10em;
    }

    &-field-user-organization {
      [data-show-more] {
        max-height: @table-font-size * @table-line-height * 3;
      }
    }
  }

  .item-list .pager {
    margin-top: 2rem;
  }
}
