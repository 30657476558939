@code-snippet-border: 1px solid @_grey_light_color;
@code-snippet-gutter-background: #f7f7f7;
@code-snippet-language-name-z-index: 2; // Should be > @code-snippet-codemirror-z-index
@code-snippet-fullscreen-language-name-z-index: 504; // Should be > @code-snippet-fullscreen-codemirror-z-index
@code-snippet-codemirror-z-index: 1;
@code-snippet-fullscreen-codemirror-z-index: 503; // Should be > @admin-navbar-tray-z-index

.code-snippet-dialog {
  .code-snippet-wrapper {
    margin: 0 -@modal-dialog-padding;
  }

  .syntax-selector {
    border-bottom: @code-snippet-border;
    padding: 0 @modal-dialog-padding @global-margin;

    select {
      float: right;
      min-width: 120px;
      width: auto;

      [dir=rtl] & {
        float: left;
      }
    }
  }

  .CodeMirror {
    direction: ltr;
    border-bottom: @code-snippet-border;
  }
}

.code-snippet-widget {
  position: relative;

  .language-name {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    padding: @global-margin-small;
    border: @code-snippet-border;
    background: @code-snippet-gutter-background;
    color: @global-text-color;
    z-index: @code-snippet-language-name-z-index;
    font-size: @global-font-size-small;

    .nei-content-roundness & {
      border-radius: @course-content-border-radius @course-content-border-radius 0 0;
    }
  }

  .code-action-button {
    font-size: inherit;

    .nei-mode-edit & {
      display: none;
    }
  }

  .CodeMirror {
    border: @code-snippet-border;
    direction: ltr;
    height: auto;
    min-height: 33px;
    padding-top: 2.375rem;
    z-index: @code-snippet-codemirror-z-index;

    .nei-content-roundness & {
      border-radius: @course-content-border-radius;
    }

    pre {
      font-size: 1em;
    }

    &-scroll {
      font-size: 1em;
    }
  }

  .nei-widget-edit-wrapper & {
    .language-name,
    .CodeMirror {
      border: none;
      border-bottom: @code-snippet-border;
    }

    .language-name {
      right: 0;
      top: 0;
    }
  }

  .CodeMirror-linenumber {
    &::selection {
      background: transparent;
      color: #999; // @see /codemirror.css
    }
  }

  // Fullscreen.
  &.is-fullscreen {
    .language-name {
      position: fixed;
      z-index: @code-snippet-fullscreen-language-name-z-index;
    }

    .CodeMirror-fullscreen {
      z-index: @code-snippet-fullscreen-codemirror-z-index;
    }

    .nei-content-roundness & {
      .language-name,
      .CodeMirror {
        border-radius: 0;
      }
    }
  }
}
