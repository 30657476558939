@image-editor-bg-color: #232424;
@image-editor-toolbar-color: #ffffff;
@image-editor-toolbar-height: 8rem;
@image-editor-cropper-large-point-size: 10px;
@image-editor-transparent-bg: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');

body.has-active-cropper {
  overflow: hidden;
}

.cropper-editor-overlay {
  .position-cover(fixed);
  z-index: 505; // to cover admin's menu
  opacity: 0;

  .cropper-view-box {
    outline: 1px solid;
  }

  .cropper-dashed {
    opacity: 1;
    border: 0 solid #ffffff;

    &.dashed-v {
      border-left-width: 1px;
      border-right-width: 1px;
    }

    &.dashed-h {
      border-bottom-width: 1px;
      border-top-width: 1px;
    }
  }

  .cropper-point {
    opacity: 1;

    [dir=ltr] & {
      &.point-se {
        width: @image-editor-cropper-large-point-size;
        height: @image-editor-cropper-large-point-size;
      }
    }

    [dir=rtl] & {
      &.point-sw {
        width: @image-editor-cropper-large-point-size;
        height: @image-editor-cropper-large-point-size;
      }
    }
  }

  .cropper-line,
  .cropper-center {
    display: none;
  }
}

.cropper-editor-container {
  width: 100%;
  height: 100%;
  padding: @image-editor-toolbar-height 0;
  background-color: @image-editor-bg-color;

  .cropper-container {
    background-image: none;
  }

  .cropper-canvas {
    background-image: @image-editor-transparent-bg;
  }
}

.cropper-editor-image {
  width: 100%; // is important to set
  height: 100%; // is important to set
  background-color: @image-editor-bg-color;
  .position-center(relative);

  #cropper-editor-image {
    max-width: 100%;
    opacity: 0;
  }
}

.cropper-editor-toolbar {
  height: @image-editor-toolbar-height;
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 1.875rem;
  background-color: #3c3e3e;
  color: @image-editor-toolbar-color;

  &.top {
    top: 0;

    .file-name,
    .crop-mode {
      margin: 0 auto;
      font-size: @global-font-size-large;
      opacity: .8;
    }

    .file-name {
      max-width: 60%;
      text-align: center;
      .text-ellipsis;
    }
  }

  &.bottom {
    bottom: 0;
  }

  a {
    margin: @global-margin 1.25rem;
    padding: @global-margin-small;
    text-align: center;
    color: @image-editor-toolbar-color;
    opacity: .8;
    text-decoration: none;
    border-radius: @button-border-radius-small;

    &.inactive {
      cursor: default;

      &,
      &:hover,
      &:focus {
        opacity: .2;
      }
    }

    &:hover,
    &:focus {
      color: @image-editor-toolbar-color;
      opacity: 1;
    }

    [dir=ltr] & {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    [dir=rtl] & {
      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }
  }
}

@media all and (min-width: @breakpoint-medium) and (max-width: @breakpoint-large-max) {
  .cropper-editor-toolbar {
    &.top .file-name {
      max-width: 50%;
    }
  }
}

@media all and (max-width: @breakpoint-large-max) {
  .cropper-editor-container {
    padding: (@image-editor-toolbar-height * .75) 0;
  }

  .cropper-editor-toolbar {
    height: @image-editor-toolbar-height * .75;

    a {
      margin: @global-margin @global-margin-medium;
    }
  }
}

@media all and (max-width: @breakpoint-small-max) {
  .cropper-editor-container {
    padding: (@image-editor-toolbar-height * .5) 0;
  }

  .cropper-editor-toolbar {
    height: @image-editor-toolbar-height * .5;

    a {
      margin: @global-margin;
    }

    &.top {
      .file-name {
        display: none;
      }

      .final-actions {
        [dir=ltr] & {
          margin-left: auto;
        }

        [dir=rtl] & {
          margin-right: auto;
        }
      }
    }
  }
}

@media all and (max-width: @breakpoint-mini-max) {
  .cropper-editor-toolbar {
    a {
      margin: @global-margin;
    }

    &.bottom {
      .crop-mode {
        justify-content: initial;
      }

      .apply {
        [dir=ltr] & {
          margin-left: auto;
        }

        [dir=rtl] & {
          margin-right: auto;
        }
      }
    }
  }
}
