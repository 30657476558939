.activity-assignment-extra-info {
  margin: @global-margin-medium 0;

  & > * {
    [dir=ltr] & {
      float: left;
      margin-right: @global-margin-medium * 2;
    }

    [dir=rtl] & {
      float: right;
      margin-left: @global-margin-medium * 2;
    }
  }

  .total-users {
    color: @_grey_dark_color;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.assignment-submission,
.activity-assignment-extra-info {
  .md-icon.passed {
    color: #6ec300;
  }

  .md-icon.failed {
    color: #f40000;
  }
}

.assignment-submission-section .submission-actions a {
  text-decoration: none;
}

.assignment-submissions-section {
  form label {
    display: none;
  }

  .submissions-content table {
    margin-top: 0;
    word-break: break-word;

    .title-cell {
      min-width: 12rem;
      max-width: 15rem;
    }

    .profile-avatar {
      width: @global-size-medium;
      height: @global-size-medium;
    }

    .actions-cell {
      .uk-button {
        white-space: nowrap;
      }
    }
  }
}

.activity-groups-list {
  td, th {
    vertical-align: top;
  }

  .title-cell {
    min-width: 20rem;
  }

  .description-cell {
    min-width: 15rem;
    max-width: 20rem;

    [data-show-more] {
      max-height: calc(@table-font-size * @table-line-height * 2);
    }
  }
}
