:root {
  // Overrides generic sizes.
  --ck-font-face: inherit;
  --ck-line-height-base: 1.25;
  --ck-font-size-base: @global-font-size-small;
  --ck-font-size-tiny: @global-font-size-xsmall;
  --ck-font-size-small: @global-font-size-small;
  --ck-font-size-normal: @global-font-size;
  --ck-font-size-big: @global-font-size-large;
  --ck-font-size-large: @global-font-size-xlarge;
  --ck-border-radius: @inputs-border-radius;
  --ck-ui-component-min-height: 2rem;
  --ck-inner-shadow: 0 none;
  --ck-spacing-unit: .5rem;

  // Helper variables to avoid duplication in the colors.
  --ck-custom-background: transparent;
  --ck-custom-foreground: transparent;
  --ck-custom-border: @form-border;
  --ck-custom-text: inherit;

  // Overrides generic colors.
  --ck-color-base-background: var(--ck-custom-background);
  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-base-border: var(--ck-custom-border);
  --ck-color-focus-border: @form-focus-border;
  --ck-color-base-text: var(--ck-custom-text);
  --ck-color-engine-placeholder-text: @global-placeholder-color;
  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-save: @text-success-color;
  --ck-color-button-cancel: @text-danger-color;
  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);
  --ck-color-dropdown-panel-background: @dropdown-background;
  --ck-tooltip-text-padding: @tooltip-padding-horizontal;
  --ck-list-button-padding: @dropdown-padding;
  --ck-z-panel: calc(@modal-z-index + 1);
}

// Overrides styles.
.ck.ck-input {
  &,
  & + .ck-label {
    font-size: @global-font-size-small;
  }
}

.ck.ck-balloon-panel {
  --ck-color-labeled-field-label-background: @dropdown-background;

  border: 0 none;
  background: @dropdown-background;
  color: @dropdown-color;
  font-size: @dropdown-font-size;
  box-shadow: @dropdown-box-shadow;
  line-height: @dropdown-line-height;

  .ck.ck-list {
    padding: @dropdown-padding;
    border-radius: 0;
    margin: 0 -@dropdown-padding;

    .ck.ck-button {
      padding: @nav-dropdown-padding-vertical @nav-dropdown-padding-horizontal;
    }
  }

  &.ck-tooltip {
    padding: @tooltip-padding-vertical @tooltip-padding-horizontal;

    .ck-tooltip__text {
      font-size: @tooltip-font-size;
      line-height: @tooltip-line-height;
    }
  }

  &.ck-balloon-panel_with-arrow {
    &:after,
    &:before {
      display: none;
    }
  }

  .ck-rounded-corners &,
  &.ck-rounded-corners {
    border-radius: @dropdown-border-radius;
  }

  .ck.ck-button {
    border: 0 none;

    &.ck-link-actions__preview {
      line-height: 1.5;
    }

    &:focus,
    &:hover {
      background: @_grey_lighten_color; // .uk-button-secondary {}
    }
  }
}

.ck-powered-by-balloon {
  &,
  &.ck.ck-balloon-panel.ck-balloon-panel_visible {
    display: none;
  }
}

.ck.ck-color-selector {
  --ck-color-labeled-field-label-background: @dropdown-background;

  max-width: none;
  padding: @dropdown-padding;

  .ck-color-grid {
    padding-inline: 0;
  }

  .ck-color-grids-fragment {
    .ck-button {
      &.ck-color-selector__color-picker,
      &.ck-color-selector__remove-color {
        border: 1px solid var(--ck-color-base-border);
        border-radius: var(--ck-border-radius) !important;
      }
    }
  }

  .ck.ck-input-text {
    height: var(--ck-ui-component-min-height);
  }
}

// Editor custom wrapper.
.mtx-text-editor-ckeditor5 {
  // .ck class styles.
  .ck.ck-editor__editable_inline {
    padding: 0 @form-padding-x;
  }

  .ck.ck-editor__editable {
    &.ck-focused:not(.ck-editor__nested-editable) {
      border-color: @form-focus-border;
    }
  }

  // .ck-button class styles.
  .ck.ck-button,
  a.ck.ck-button {
    line-height: var(--ck-line-height-base);
  }

  // .ck-toolbar class styles.
  .ck.ck-toolbar {
    padding: 0 @form-padding-x / 2;
  }

  // .ck-dropdown class styles.
  .ck-dropdown__panel {
    --ck-color-text: @dropdown-color;
  }

  .ck.ck-dropdown .ck-dropdown__panel {
    max-height: 20rem;
    overflow-y: auto;
  }

  .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on {
    // We cannot use aria-expanded as it's true in case button is highlighted,
    // no matter that dropdown isn't shown.
    &:not([data-cke-tooltip-disabled="true"]) {
      border-radius: var(--ck-border-radius);
    }
  }

  // Custom styles.
  h1, h2, h3, h4, h5, h6,
  .ck[class*=ck-heading_heading] {
    font-weight: unset;
    padding-top: @global-margin-mini;
    padding-bottom: @global-margin-mini;
  }

  .ck.ck-heading_heading1 {
    font-size: @base-h1-font-size;
  }

  .ck.ck-heading_heading2 {
    font-size: @base-h2-font-size;
  }

  .ck.ck-heading_heading3 {
    font-size: @base-h3-font-size;
  }

  .ck.ck-heading_heading4 {
    font-size: @base-h4-font-size;
  }

  .ck.ck-heading_heading5 {
    font-size: @base-h5-font-size;
  }

  .ck.ck-heading_heading6 {
    font-size: @base-h6-font-size;
  }

  .ck.ck-editor__editable_inline > :last-child {
    margin-bottom: 0;
  }

  .ck.ck-editor__editable_inline > :only-child {
    margin-bottom: 2px; // Fix mention and token outline.
  }

  &.with-toolbar {
    .ck-editor.toolbar-hidden {
      .ck.ck-sticky-panel {
        display: none;
      }
    }

    .ck-sticky-panel__content {
      border-width: 0 0 1px !important;
    }

    .ck-content {
      border: 0 none !important;
    }
  }

  &.without-toolbar {
    .form-textarea-wrapper {
      border: 0 none;
    }
  }

  // Mention styles.
  .ck-content {
    .mtx-mention {
      display: inline-block;
      border-radius: @global-border-radius;
      padding: @global-margin-mini @global-margin;
      line-height: 1.2;
      transition: background-color .15s linear;
      cursor: default;
      margin: .125rem 0.0625rem;
    }
  }
}
