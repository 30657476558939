.activity-overview-page {
  @list-item-margin: @global-margin-mini;

  .activity-overview-details {
    font-size: @global-font-size-small;

    .list {
      .item + .item {
        margin-top: @list-item-margin;
      }

      .title {
        font-weight: @global-font-weight-semibold;
      }
    }
  }

  .activity-modules {
    .mtx-section-title {
      word-break: break-word;
      margin: 0;
    }

    .uk-accordion-title {
      margin-bottom: 0;
      padding: calc(@button-padding-vertical - 1px); // .uk-button-circle {}
    }

    .uk-accordion > .uk-accordion-content {
      // Prevents widget expanding on page load.
      display: none;
    }
  }

  .mtx-load-more-button {
    margin-top: 1.25rem;
  }

  @media all and (max-width: @breakpoint-medium-max) {
    .activity-comment {
      margin-top: @global-margin-medium;
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .activity-overview-details {
      .list + .list {
        margin-top: @list-item-margin;
      }
    }

    .activity-comment {
      margin-top: @list-item-margin;
    }
  }
}

// Participants table.
.mtx-activity-signups {
  .filters {
    .search {
      .input-search-icon;
    }
  }

  table {
    font-size: @table-font-size;
  }

  // Filters.
  .mtx-date-field {
    input {
      height: @form-height;
    }

    .date-field-clear[disabled] {
      display: none;
    }
  }

  // Cells.
  .checkbox-cell {
    [dir=ltr] & {
      padding-right: @global-margin-medium - @table-cell-padding;
    }

    [dir=rtl] & {
      padding-left: @global-margin-medium - @table-cell-padding;
    }
  }

  .name-cell {
    min-width: 15rem;
    max-width: 18rem;
    word-break: break-word;

    .avatar {
      min-width: @mtx-users-avatar-size;

      &,
      img {
        .circle(@mtx-users-avatar-size);
      }
    }

    .text > div + div {
      margin-top: @global-margin-mini;
    }
  }

  .created-cell {
    min-width: 10rem;
  }

  .created-by-cell {
    min-width: 15rem;
  }

  .order-cell,
  .status-cell {
    min-width: 7rem;
  }

  .price-cell {
    @input-height: @global-size-small;
    @input-font-size: @global-font-size-small;

    .signup-edit-price {
      display: inline-block;
      white-space: nowrap;

      .md-icon {
        font-size: 1.25rem;
      }
    }

    input {
      height: @input-height;
      padding: ((@input-height - @input-font-size * @global-line-height) / 2) @global-margin;
      font-size: @global-font-size-small;
      overflow: hidden; // Safari fix for overflowed input
    }
  }

  .mtx-advanced-instructor-activity-participants-section & table {
    width: auto;
  }
}

.signup-anonymous-avatar {
  .mtx-anonymous-avatar-icon(@mtx-users-avatar-size);
}

// Alerts timeline popup.
#mtx-alerts-timeline-popup {
  .uk-modal-content {
    padding: @global-margin-medium 0 1.5625rem;
  }
}

.mtx-alerts-timeline {
  @list-icon-size: 2.5rem;
  @list-icon-margin: 1rem;
  @list-meta-font-size: @global-font-size-xsmall;
  @list-content-margin-small: @list-icon-size-small + @list-icon-margin;

  @divider-width: .125rem;
  @divider-margin: @global-margin-mini;

  &.mtx-list {
    li + li,
    & + .mtx-list-item {
      margin-top: @list-icon-margin;
    }
  }

  .mtx-list-item {
    position: relative;
    padding: 0;
    clear: both;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: @list-icon-size + @divider-margin;
      width: @divider-width;
      height: calc(100% - @list-icon-margin - @divider-margin * 2);
      border-radius: @divider-width;
      background-color: @_grey_lighten_color;

      [dir=ltr] & {
        left: (@list-icon-size / 2) - (@divider-width / 2);
      }

      [dir=rtl] & {
        right: (@list-icon-size / 2) - (@divider-width / 2);
      }
    }

    &:last-child:after {
      display: none;
    }

    .icon {
      width: @list-icon-size;
      max-width: @list-icon-size;
      margin: 0;
      margin-inline-end: @list-icon-margin;

      .md-icon {
        width: @list-icon-size;
        line-height: @list-icon-size;
      }

      &.processed {
        .md-icon {
          background-color: @_black_color;
          color: @_white_color;
        }
      }

      & + .item-content {
        margin-inline-start: @list-content-margin;
      }
    }

    .title {
      font-size: @global-font-size-small;

      & > .meta {
        font-size: @list-meta-font-size;
      }
    }

    .meta {
      font-size: @list-meta-font-size;
    }

    .item-content {
      min-height: @list-icon-size;
      display: flex;
      align-items: center;
    }
  }
}
