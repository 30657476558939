// Avatar canvas icon.
.mtx-avatar-icon {
  max-width: 100%;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .05);

  &[data-rounded] {
    border-radius: @global-border-radius;
  }
}

.mtx-anonymous-avatar-icon(@size: 3rem) {
  .circle(@size);
  text-align: center;
  background-color: @_grey_lighten_color;
  color: @_grey_dark_color;

  .md-icon {
    line-height: @size;
  }

  td.active & {
    background-color: darken(@table-active-cell-bg-color, 5%);
  }

  .table-row-muted & {
    opacity: .5;
  }

  .mtx-dark-theme &,
  .mtx-dark-theme td.active & {
    background-color: @overlay-color-light;
    color: @_grey_light_color;
  }
}

.mtx-avatar-items-group {
  margin-left: @global-margin;

  .item-wrapper {
    min-width: 2.75rem;
    width: 2.75rem !important;
    height: 2.75rem !important;
    margin-inline-start: -@global-margin;
    z-index: 1;
  }

  .mtx-avatar-icon {
    border: 1px solid @global-base-color;
    max-width: 100%;
  }
}

.mtx-counter-avatar-icon {
  .square(2.75rem);
  position: relative;
  font-size: @global-font-size-xsmall;
  max-width: 100%;
  border: 1px solid @global-base-color;
  border-radius: 50%;
  background-color: #EAEAEA;

  &[data-rounded] {
    border-radius: @global-border-radius;
  }

  .text {
    .position-center();
  }
}
